import { Component, ComponentClass, FC, Fragment } from "react";
import { connect } from "react-redux";
import HomeTemplate from "../components/templates/HomeTemplate";
import { WithRouterProps, withRouter } from "../helpers/RouterHelper";
import AppStateInterface from "../interfaces/AppStateInterface";
import OperatorInterface from "../interfaces/OperatorInterface";
import enhanceAuthenticatedPage, {
  AuthenticateRouterProps,
} from "./enhanceAuthenticatedPage";
import withErrorPages from "./withErrorPages";

interface HomePageProps extends WithRouterProps<AuthenticateRouterProps> {
  operators: OperatorInterface[];
  currentOperator: OperatorInterface | null;
}

interface PageInfoInterface {
  title: string;
}

const mapStateToProps = (state: AppStateInterface) => ({
  operators: state.session.loggedInOperators,
  currentOperator: state.session.currentOperator,
});

const enhanceHomePage = (
  WrappedComponent: ComponentClass<any, any> | FC<any>,
  pageInfo: PageInfoInterface,
): ReturnType<typeof withRouter> => {
  class HomePage extends Component<HomePageProps> {
    render() {
      if (this.props.currentOperator) {
        return (
          <Fragment>
            <HomeTemplate
              pageInfo={pageInfo}
              operators={this.props.operators}
              currentOperator={this.props.currentOperator}
            >
              <WrappedComponent {...this.props} />
            </HomeTemplate>
          </Fragment>
        );
      } else {
        return null;
      }
    }
  }
  return withRouter<HomePageProps>(
    withErrorPages(
      connect(mapStateToProps, null)(enhanceAuthenticatedPage(HomePage)),
    ) as any,
  );
};
export default enhanceHomePage;
